import * as arr from "helpers/array.js";
import {getDimension} from "helpers/array";

const options = [
	"rename-sheet",
	"remove-sheet"
];

const actionMap = {
	"remove-sheet": function(view){
		view.confirm({
			text: webix.i18n.spreadsheet.labels["sheet-remove-confirm"],
			callback:(res) => {
				if (res)
					remove(view, view._activeSheet);
			}
		});
	},
	"rename-sheet": function(view){
		edit(view, view._activeSheet);
	},
	"new-sheet": function(view){
		add(view);
	},
	"copy-sheet": function(view){
		copy(view);
	}
};

export function init(view){
	view.attachEvent("onComponentInit", () => ready(view));
}

export function defaultBottom(view){
	var readonly = view.config.readonly;
	var toolbar =  {
		view: "toolbar",
		id: "bottom-toolbar",
		css: "webix_ssheet_bottom_toolbar webix_layout_toolbar",
		paddingY: 0,
		height: 34,
		elements:[
			{
				view: "button", type:"htmlbutton", width: 34, id: "add-sheet",
				label: "<span class='webix_ssheet_icon_add_sheet'></span>",
				tooltip: webix.i18n.spreadsheet.tooltips["add-sheet"]||"", hidden:readonly
			},
			{
				id:"sheets",
				view:"sheets",
				xCount: 5,
				editable:!readonly,
				editaction: "dblclick",
				editor:"text",
				editValue:"value",
				type:{
					width: view.config.sheetTabWidth,
					height: 32,
					template: function(obj){
						return "<div>"+obj.value+"</div>"+
							(!readonly?"<div class='webix_input_icon fa-angle-down webix_ssheet_sheet_arrow'></div>":"");
					}
				},
				onClick:{
					webix_ssheet_sheet_arrow: function(e, id){
						initSheetMenu(view, e, id);
					}
				},
				on:{
					onItemClick: function(id){
						if(id != view._activeSheet)
							view.showSheet(id);
					}
				}
			},
			{
				id: "menu-pager",
				view: "pager",
				autosize: true,
				template: function(){
					var html = "<button type='button' webix_p_id='prev' class='webix_pager_item'><span class='webix_icon fa-caret-left'></span></button>";
					html += "<button type='button' webix_p_id='next' class='webix_pager_item'><span class='webix_icon fa-caret-right'></span></button>";
					return html;
				}
			}
		]
	};

	view.callEvent("onViewInit", ["bottom-toolbar", toolbar]);

	return toolbar;
}

function menuInit(view){
	var data = [];
	for(var i=0; i < options.length;i++)
		data.push({id: options[i], value:webix.i18n.spreadsheet.menus[options[i]]||options[i]});

	var menu =  {
		view: "ssheet-suggest",
		data: data
	};
	view.callEvent("onViewInit", ["sheet-menu", menu]);
	return menu;
}

export function reset(view, obj){
	let tabs = view.$$("sheets");
	if (tabs){
		tabs.clearAll();
		let set = [];
		obj.sheets.forEach(function(sheet){
			set.push({id:sheet.name, value: sheet.name});
		});
		tabs.parse(set);
	}
}

function ready(view){
	prepare(view);

	if(view.$$("add-sheet"))
		view.$$("add-sheet").attachEvent("onItemClick", function(){
			add(view);
		});

	initPager(view);

	view.attachEvent("onAfterSheetShow", (name) => selectSheet(view,name));

	view.attachEvent("onCommand", function(command){
		if(actionMap[command.id])
			actionMap[command.id](this);
	});

	if(view.$$("sheets")){
		view.$$("sheets").data.attachEvent("onStoreUpdated", ()=> setPagerState(view));
		view.$$("sheets").attachEvent("onAfterEditStop", (state) =>rename(view,state.old,state.value));
	}
}

function initSheetMenu(view, e, id){
	if(!view.$sheetMenu){
		view.$sheetMenu = webix.ui(menuInit(view));
		view.$sheetMenu.attachEvent("onItemClick", (id)=> callAction(view,id));
	}

	if(view.$sheetMenu.getItem("remove-sheet"))
		if(view.$$("sheets").count() == 1)
			view.$sheetMenu.disableItem("remove-sheet");
		else
			view.$sheetMenu.enableItem("remove-sheet");
	if(view.callEvent("onBeforeSheetMenu", [id])){
		view.$sheetMenu.show(e);
	}
}

function initPager(view){
	if(view.$$("menu-pager")){

		view.$$("sheets").define("pager",view.$$("menu-pager"));

		view.$$("menu-pager").attachEvent("onAfterRender", function(){

			if(!this.data.page)
				webix.html.addCss(this.$view.firstChild,"ssheet_pager_inactive",true);
			else
				webix.html.removeCss(this.$view.firstChild,"ssheet_pager_inactive",true);

			if(this.data.page == this.config.limit-1)
				webix.html.addCss(this.$view.lastChild,"ssheet_pager_inactive",true);
			else
				webix.html.removeCss(this.$view.lastChild,"ssheet_pager_inactive",true);
		});
	}
}

export function add(view, content){
	var index, name, newIndex;

	index = arr.findIndex(view._sheets, x => x.name == view._activeSheet);
	newIndex = index>-1?index+1:view._sheets.length;
	name = getNewTitle(view);
	content = content || {data:[]};
	view._sheets.splice(newIndex, 0, {
		name: name,
		content: content
	});
	view.$$("sheets").add({id: name, value: name},newIndex);
	view.showSheet(name);
	view.callEvent("onSheetAdd", [name]);
	return name;
}

function getNewTitle(view){
	var index = view._sheets.length+1;
	while(getSheet(view, (webix.i18n.spreadsheet.labels.sheet+index)))
		index++;
	var name = webix.i18n.spreadsheet.labels.sheet+index;
	return name;
}

function selectSheet(view,name){
	view.$$("sheets").select(name);
	if(view.$$("menu-pager") && view.$$("sheets").config.xCount) {
		var index = view.$$("sheets").getIndexById(name);
		var page = Math.floor(index / view.$$("sheets").config.xCount);
		view.$$("menu-pager").select(page);
	}
}


function setPagerState(view){
	if(view.$$("menu-pager")){
		if(view.$$("sheets").count() <= view.$$("sheets").config.xCount){
			webix.html.addCss(view.$$("menu-pager").$view,"webix_sheet_hidden",true);
		}
		else{
			webix.html.removeCss(view.$$("menu-pager").$view,"webix_sheet_hidden");
		}
	}
}

export function edit(view,name){
	view.$$("sheets").edit(name);
}

export function remove(view,name){
	var index, newSheet;
	if(view._sheets.length >1){
		index = arr.findIndex(view._sheets,x => x.name == name);
		view._sheets.splice(index,1);
		if(!view._sheets[index])
			index = 0;
		newSheet = 	view._sheets[index];
		view._activeSheet = null;
		if(view.$$("sheets")){
			view.$$("sheets").remove(name);
			view.$$("sheets").refresh();
		}
		view.showSheet(newSheet.name);
		view.callEvent("onSheetRemove", [name]);
	}
}

export function rename(view,name,newName){
	if (name == newName) return;

	//correct sheet name
	var i = 1;
	while (!newName || getSheet(view, newName)){
		newName = webix.i18n.spreadsheet.labels.sheet + i;
		i++;
	}
	newName = newName.replace(/[*?:[\]\\/]/g,"").substring(0, 31);

	var sheet = getSheet(view, name);
	var sheets = view.$$("sheets");

	sheet.name = newName;
	if(view._activeSheet == name)
		view._activeSheet = newName;

	if(sheets){
		let item = sheets.getItem(name);
		item.value = newName;
		sheets.data.changeId(name,newName);
		sheets.refresh(newName);
	}

	view.callEvent("onSheetRename", [name, newName]);
}

export function serialize(view, obj){
	getSheet(view, view._activeSheet).content = obj;
	return view._sheets;
}

export function load(view, obj){
	obj = webix.isArray(obj)?{sheets:obj}:obj;
	
	if((obj.excel || !obj.sheets) && view._activeSheet){
		//loading into active sheet
		var name = view._activeSheet;
		getSheet(view, name).content = obj;
		refresh(view, obj);
	} else {
		//creating new set of sheets
		prepare(view, obj);
	}
}

export function prepare(view, obj){
	if(!obj) obj = {data:[]}; 
	if(!obj.sheets)
		obj = newSheet(obj);
	
	var name = obj.sheets[0].name;
	view._activeSheet = "";
	view._sheets = obj.sheets;
	reset(view, obj);
	show(view, name);
}

export function newSheet(obj){
	return {
		sheets:[{
			name: webix.i18n.spreadsheet.labels.sheet + 1,
			content: obj
		}]
	};
}

export function show(view,name){
	if (name == view._activeSheet) return;

	if(view.callEvent("onBeforeSheetShow", [name])){
		if (view._activeSheet)
			getSheet(view, view._activeSheet).content = view.serialize();

		view._activeSheet = name;
		var obj = getSheet(view, name).content;
		refresh(view, obj);

		view.callEvent("onAfterSheetShow", [name]);
	}
}

export function getSheet(view, name){
	return arr.find(view._sheets, x => x.name == name);
}

export function eachSheet(view, callback){
	for (var i=0; i<view._sheets.length; i++)
		callback(view._sheets[i].name);
}

export function getActive(view){
	return view._activeSheet;
}

function refresh(view, obj){
	let [rows, cols] = getDimension(obj.data, view.config.rowCount, view.config.columnCount);
	view.callEvent("onReset", []);
	if (rows != view.config.rowCount || cols != view.config.columnCount){
		view.config.rowCount = rows;
		view.config.columnCount = cols;
		view._resetTable();
	}

	view.callEvent("onDataParse", [obj]);
	view._table.refresh();
	if(view.config.bottombar)
		view.$$("sheets").refresh();
}

function copy(view){
	add(view, view.serialize());
}

function callAction(view, id){
	if(!view.$sheetMenu.data.getMark(id, "webix_disabled"))
		actionMap[id](view);
}

