import {addMethod} from "../math/methods.js";
import {image, chart} from "../math/writer.js";
import {getAccessor} from "../math/sheet.js";

export function addImage(row, column, url){
	this.setCellValue(row, column, image(url));
	this.refresh();
}

export function addSparkline(row, column, config){
	let value = chart(config);
	this.setCellValue(row, column, value);
	this.refresh();
}

export function getSheetData(name){
	return getAccessor(this, name);
}

export function recalculate(){
	this.callEvent("onMathRefresh",[]);
}

export var registerMathMethod = addMethod;