// toolbar: color selector
webix.protoUI({
	$cssName: "colorboard",
	name: "ssheet-colorboard",
	defaults:{
		css: "webix_ssheet_colorboard",
		palette: [
			["#000000", "#434343", "#666666", "#999999", "#b7b7b7", "#cccccc", "#d9d9d9", "#efefef", "#f3f3f3", "#ffffff"],
			["#980000", "#ff0000", "#ff9900", "#ffff00", "#00ff00", "#00ffff", "#4a86e8", "#0000ff", "#9900ff", "#ff00ff"],
			["#e6b8af", "#f4cccc", "#fce5cd", "#fff2cc", "#d9ead3", "#d0e0e3", "#c9daf8", "#cfe2f3", "#d9d2e9", "#ead1dc"],
			["#dd7e6b", "#ea9999", "#f9cb9c", "#ffe599", "#b6d7a8", "#a2c4c9", "#a4c2f4", "#9fc5e8", "#b4a7d6", "#d5a6bd"],
			["#cc4125", "#e06666", "#f6b26b", "#ffd966", "#93c47d", "#76a5af", "#6d9eeb", "#6fa8dc", "#8e7cc3", "#c27ba0"],
			["#a61c00", "#cc0000", "#e69138", "#f1c232", "#6aa84f", "#45818e", "#3c78d8", "#3d85c6", "#674ea7", "#a64d79"],
			["#85200c", "#990000", "#b45f06", "#bf9000", "#38761d", "#134f5c", "#1155cc", "#0b5394", "#351c75", "#741b47"],
			["#5b0f00", "#660000", "#783f04", "#7f6000", "#274e13", "#0c343d", "#1c4587", "#073763", "#20124d", "#4c1130"]
		]
	}
},webix.ui.colorboard);

webix.protoUI({
	$cssName: "richselect",
	name: "ssheet-color",
	defaults:{
		css: "webix_ssheet_color",
		icon: "angle-down",
		suggest:{
			height: 202,
			borderless: true,
			body:{
				view: "ssheet-colorboard",
				on:{
					onSelect: function(value){
						this.getParentView().setMasterValue({ value:value });
					}
				}
			}
		}
	},
	$init: function(){
		this.$view.className += " webix_ssheet_color";
	},
	$renderInput: function(config, divStart, id){
		var color  = this.renderColor.call(this);
		divStart = divStart.replace(/([^>]>)(.*)(<\/div)/, function(match, p1, p2, p3){
			return p1+config.title +color+p3;
		});

		return webix.ui.colorpicker.prototype.$renderInput.call(this,config, divStart, id);
	},
	$setValue: function(value){
		var popup =  webix.$$(this.config.popup.toString());
		var colorboard = popup.getBody();
		if(value){
			colorboard.setValue(value);
			this.config.value = value;
			this.getColorNode().style.backgroundColor = value;
		}

	},
	renderColor: function(){
		return "<div class='webix_ssheet_cp_color' style='background-color:"+this.config.value+";'> </div>";
	},
	getColorNode: function(){
		return this.$view.firstChild.firstChild.childNodes[1];
	},
	$renderIcon:function(){
		return webix.ui.text.prototype.$renderIcon.apply(this,arguments);
	}
},webix.ui.colorpicker);