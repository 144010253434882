import * as hrows from "operations/hide_row_col.js";

export function hideColumn(column,state){
	hrows.setColumnState(this, column, state);
}
export function isColumnVisible(column){
	return hrows.isColumnVisible(this, column);
}
export function hideRow(row,state){
	hrows.setRowState(this, row, state);
}
export function isRowVisible(row){
	return hrows.isRowVisible(this, row);
}