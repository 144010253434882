import {group} from "./undo.js";
import {changeRange} from "helpers/column_names.js";

export function init(view){
	view.attachEvent("onCommand", (cm) => {
		if (cm.id === "create-filter")
			filter(view);
	});
	view.attachEvent("onUndo", (type, row, column, value) => {
		if (type === "filter"){
			if (!value)
				view.removeFilter();
			else
				view.setCellFilter(row, column, value.options);
		} else if (type == "dropdown"){
			view.setCellEditor(row, column, value);
		}
	});

	view.attachEvent("onAction", (action, p)=> {
		if(action == "before-grid-change")
			updatePosition(p.name,p.inc,p.data, p.start);
	});
}


export function getFilters(table){
	var filters = table._ssFilters;
	var all = [];
	for (var row in filters)
		for (var column in filters[row])
			all.push(filters[row][column]);
	return all;
}

export function serialize(view,data){
	let filters = getFilters(view._table);
	let editors = view._table._ssEditors;
	
	let result = data.filters = [];
	for (var i = 0; i<filters.length; i++){
		let [row, column] = filters[i];
		let options = editors[row][column].options;
		if(options)
			result.push([row,column,options]);
	}
}

export function load(view,data){
	var i,
		filters = data.filters;

	if(filters){
		for(i = 0; i < filters.length; i++){
			var value = view.getCellValue(filters[i][0],filters[i][1]);
			view.setCellFilter.apply(view,filters[i]);
			if (value){
				view.setCellValue(filters[i][0],filters[i][1],value);
			}
		}
		view.filterSpreadSheet();
	}
}

function filter(view, range){
	range = range || view._table.getSelectArea();

	group.start();
	view.removeFilter();
	if (!range) return;
	let row = range.start.row;
	for (let i=range.start.column; i<=range.end.column; i++){
		view.setCellFilter(row, i, range);
		view.callEvent("onAction", ["filter", { row, column:i, value:null, newValue:range }]);
	}
	group.end();
}

function updatePosition(name, inc, data, start){
	let i,
		filters = data.filters;

	if(inc){
		for(i = filters.length-1;i >=0;i--){
			let [row,column,range] = filters[i];
			if(name == "row" && row>= start.row || name == "column" && column >= start.column){
				filters[i][0] = name == "row"?row*1 + inc:row;
				filters[i][1] = name == "column"?column*1 + inc:column;
				filters[i][2] = changeRange(range, name, inc, start);
			}
		}
	}
}