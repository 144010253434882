webix.protoUI({
	name:"formlist",
	defaults:{
		paddingY: 5,
		height: 110,
	},
	$init:function(config){

		config.cols = [
			{
				template:config.label,
				width:config.labelWidth,
				borderless:true
			},
			{
				view:"list",
				data:config.data,
				css:config.css,
				template:config.template,
				select: true,
				scroll:false,
				borderless:true,
				on:{
					onSelectChange:()=>{
						this.getParentView().callEvent("onChange", []);
					}
				}
			}
		];
	},
	setValue:function(value){this.getChildViews()[1].select(value);},
	getValue:function(){return this.getChildViews()[1].getSelectedId();},
	refresh: function(){this.getChildViews()[1].refresh();},
	focus:function(){}
}, webix.ui.layout);