webix.protoUI({
	name: "ssheet-borders-suggest",
	defaults:{
		width: 300
	},
	$init: function(config){
		config.body = {
			margin: 6,
			cols:[
				{ view: "ssheet-icons", scroll: false, select: true,
					xCount: 4,
					yCount: 2,
					tooltip: {
						template: function(obj){
							return webix.i18n.spreadsheet.tooltips["borders-"+obj.id];
						}
					},
					on:{
						onAfterSelect: function(){
							var suggest = this.getParentView().getParentView();
							suggest.updateMasterValue(true);
						}
					},
					template: function(obj){
						var css = "webix_ssheet_button_icon webix_ssheet_icon_borders_"+obj.value;
						return "<span class='"+css+"'></span>";
					},
					data: config.data
				},
				{ view: "ssheet-separator"},
				{
					rows:[
						{ view: "ssheet-color", css: config.css,  name: config.name, width: 62, value: "#000000",
							tooltip: webix.i18n.spreadsheet.tooltips["borders-color"],
							title: "<span class='webix_icon fa-pencil'></span>",
							on:{
								onChange: function(){
									var suggest = this.getParentView().getParentView().getParentView();
									suggest.updateMasterValue(false);
								}
							}
						},
						{ }
					]
				}

			]
		};
	},
	updateMasterValue: function(hide){
		var value = this.getValue();
		var master = webix.$$(this.config.master);
		master.setValue(value);
		if(hide)
			this.hide();
	},
	setValue: function(value){
		if(value[0])
			this.getList().select(value[0]);
		if(value[1])
			this.getColorView().setValue(value[1]);
	},
	getValue: function(){
		return [this.getList().getSelectedId(), this.getColorView().getValue()||""];
	},
	getList: function(){
		return this.getBody().getChildViews()[0];
	},
	getColorView: function(){
		return this.getBody().getChildViews()[2].getChildViews()[0];
	}
},webix.ui.suggest);

webix.protoUI({
	name: "ssheet-borders",
	$cssName: "richselect",
	defaults:{
		text: "<span class='webix_ssheet_button_icon webix_ssheet_icon_borders'>"
	},
	$init: function(config){
		config.options = {
			view: "ssheet-borders-suggest",
			fitMaster: false,
			data: config.data
		};

		this.$ready.push(webix.bind(function(){
			this.getPopup().config.master = this.config.id;
		},this));

	},
	setValue: function(value){
		if(webix.isArray(value)){
			if(!this.config.value || value[0] !=  this.config.value[0] || value[1] !=  this.config.value[1]){
				this.getPopup().setValue(value);
			}
			this.config.value = value;
			if (value[0])
				this.callEvent("onChange");
		}
		return value;
	},
	getValue: function(){
		return  this.getPopup().getValue().join(",");
	},
	getList: function(){
		return this.getPopup().getBody().getChildViews()[0];
	},
	getColorView: function(){
		return this.getPopup().getBody().getChildViews()[1].getChildViews()[0];
	}
},webix.ui.richselect);