import {lockCss} from "./lock_cell.js";
import {find, findIndex} from "helpers/array.js";

export function init(view){
	view.attachEvent("onStyleChange", (row, column, style) => {
		var span = getSpan(view, row, column);
		if (span)
			span[3] = style?style.id:"";
	});

	view.attachEvent("onDataParse", (data) => {
		if (data.spans)
			for (var i = 0; i < data.spans.length; i++) {
				var span = data.spans[i];
				addSpan( view,
					{ row: span[0], column: span[1] },
					span[2]*1,
					span[3]*1,
					true 
				);
			}
	});

	view.attachEvent("onDataSerialize", (data) => {
		var spans = [];
		var pull = view.$$("cells").getSpan();
		if (pull){
			for (var rid in pull){
				var row = pull[rid];
				for (var cid in row){
					var span = row[cid];
					spans.push([rid*1, cid*1, span[0], span[1]]);
				}
			}

			data.spans = spans;
		}
	});
	
	view.attachEvent("onUndo", (type, row, column, value, isUndo) => {
		if(type == "span" || type == "split"){
			if (type == "span") 
				isUndo = !isUndo;
			undoSpan(view, row, column, value, isUndo);
		}
	});

	view.attachEvent("onAction", (name, options) => {
		if(name == "lock")
			setLockCss(view, options.row, options.column, options.newValue);
		else if(name == "before-grid-change")
			updatePosition(options.name,options.inc,options.data, options.start);
		else if(name == "check-borders")
			return checkSpanBorders(view, options.row, options.column, options.area, options.mode);
	});
}

function getSpan(view, row, column){
	var item = view.$$("cells").getSpan()[row];
	if (item)
		return item[column];
}

export function addSpan(view, cell, x, y, silent){
	if (x<2 && y<2) return;

	if(silent || view.callEvent("onBeforeSpan", [cell.row, cell.column, [x, y]])){
		var row = view.getRow(cell.row);
		var css = row.$cellCss ? (row.$cellCss[cell.column] || "") : "";
		setSpanValue(view, cell, x, y);
		view.$$("cells").addSpan(cell.row, cell.column, x, y, null, css);
		view._save("spans", {
			row:cell.row, column: cell.column,
			x:x, y:y
		});
	}
}

export function removeSpan(view, cell){
	var span = view.$$("cells").getSpan(cell.row, cell.column);
	if(span && view.callEvent("onBeforeSplit", [ cell.row, cell.column, [span[2], span[3]] ])){
		view.$$("cells").removeSpan(cell.row, cell.column);
		view._save("spans", {
			row:cell.row, column: cell.column,
			x:0, y:0
		});
	}
}

export function getRange(sel){
	var lx, ly, rx, ry;
	rx = ry = 0;
	lx = ly = Infinity;

	for (var i=0; i<sel.length; i++){
		var cx = sel[i].column*1;
		var cy = sel[i].row*1;

		lx = Math.min(cx, lx);
		rx = Math.max(cx, rx);
		ly = Math.min(cy, ly);
		ry = Math.max(cy, ry);
	}

	return {
		cell:{ row:ly, column:lx },
		x: rx-lx+1,
		y: ry-ly+1
	};
}

function undoSpan(view,row, column,value,isUndo){
	if(isUndo){
		removeSpan(view,{row: row, column: column});
	}
	else{
		addSpan(view, {row: row, column: column}, value[0], value[1]);
	}
}

function setLockCss(view, row, column, mode){
	var span = getSpan(view, row, column);
	if(span){
		if(mode){
			if(span[3].indexOf(lockCss) == -1)
				span[3] += " "+lockCss;
		}
		else
			span[3] = span[3].replace(" "+lockCss,"");
		view._table.refresh();
	}
}

function updatePosition(name, inc, data, start){
	let spans = data.spans;
	if(inc){
		for(let i = spans.length-1;i >=0;i--){
			let [row,column] = spans[i];

			if(inc < 0){//deleting
				if(name == "row" && row <= start.row-inc-1)
					inc = start.row - spans[i][0];
				else if (name == "column" && column <= start.column-inc-1)
					inc = start.column - spans[i][1];
			}

			if(name == "row" && row>= start.row || name == "column" && column >= start.column){
				spans[i][0] =  name == "row"?row*1 + inc:row;
				spans[i][1] = name == "column"?column*1 + inc:column;
			}
		}
	}
}

var borderChecks = {
	"border-right":		function(row, column, span, area){
		return area.end.column == column+span[0]-1;
	},
	"border-bottom":	function(row, column, span, area){
		return area.end.row == row+span[1]-1;
	}
};

function checkSpanBorders(view, row, column, area, mode){
	var span = getSpan(view, row, column);
	return span&&borderChecks[mode]?borderChecks[mode](row, column, span, area):false;
}

// clear merged cells except the first one
function setSpanValue(view, cell, x, y){
	var i,j,value,
		row = cell.row*1,
		column = cell.column*1,
		isEmpty = !view.getCellValue(row,column);
	for(i = row; i < row+y; i++){
		for(j = column; j < column+x; j++) {
			value = view.getCellValue(i,j);
			if(value!=="" && (j != column || i != row)){
				if(isEmpty){
					isEmpty = false;
					view.setCellValue(row,column,value);
				}
				view.setCellValue(i,j,"");
			}
		}
	}
}

export function adjustSpan (span, action, start, end, data){
	var length = action.group == "row" ? span[3] : span[2];
	var checkSpan = action.group == "row" ? [span[0]*1, span[0]*1+span[3]-1] : [span[1]*1, span[1]*1+span[2]-1];
	var selection = action.group == "row" ? [start.row, end.row] : [start.column, end.column];

	//if we add, the number of selected rows/cols is added to span, so we need original selection length
	var inc = selection[1] - selection[0] + 1;

	selection[0] = Math.max(selection[0], checkSpan[0]);
	selection[1] = Math.min(selection[1], checkSpan[1]);

	var isFirstCell = selection[0] == checkSpan[0];

	if(selection[0] <= selection[1]){ //selection is in span
		if(action.id == "del") //if we delete, only rows/cols included into span
			inc = (selection[1] - selection[0]+ 1) * -1;
		//delete smth which starts as span and less than it's length
		if(action.id == "del" && isFirstCell && length > -1*inc){
			var value = find(data, function(value) {
				return (value[0] == span[0] && value[1] == span[1]);
			});

			value[0] = action.group == "row" ? value[0] - inc : value[0];
			value[1] = action.group == "column" ? value[1] - inc : value[1];

			var oldValue = findIndex(data, function(oldValue) {
				return oldValue[0] == value[0] && oldValue[1] == value[1] && (oldValue[2] != value[2] || oldValue[3] != value[3]); 
			});

			if(oldValue > -1) //remove another data at the same place
				data.splice(oldValue,1);
		}

		if(!(isFirstCell && action.id == "add")){
			span[2] = action.group == "column" ? span[2]+inc : span[2];
			span[3] = action.group == "row" ? span[3]+inc : span[3];
		}
	}
	return span;
}

export function pasteSpan(view, extra, row, col, cutted, translate){
	//existing span
	var s = view._table.getSpan(row, col);
	if(extra && extra.span){
		var span = extra.span;
		//if we are on start position for the future span
		if(row == span[0]*1+translate.row && col == span[1]*1+translate.column){
			//delete spans in future paste zone 
			view._table.mapCells(row, col, span[3], span[2], (value, rid, cid) => {
				s = view._table.getSpan(rid,cid);
				if(s) removeSpan(view, {row:s[0], column:s[1]});
			}, true);
			addSpan(view, {row:row, column:col}, span[2], span[3]);
			if(cutted === 1)
				removeSpan(view, {row:extra.row, column:extra.col});
		}
	}
	else if(s)
		removeSpan(view, {row:s[0], column:s[1]});
}