import {actions} from "toolbar/actions.js";
import {ui,defaultStyles} from "toolbar/helpers.js";
import * as elm from "toolbar/all_elements.js";

const buttons = {
	"undo-redo": ["undo","redo"],
	"font": ["font-family","font-size","font-weight","font-style","text-decoration","color","background","borders"],
	"align": ["text-align","vertical-align","wrap","span"],
	"format": ["format"]
};


export function init(view){
	view.attachEvent("onComponentInit", () => ready(view));
	var elements = [];

	if(view.config.toolbar){
		let toolbarElements =  view.config.toolbar;
		if(toolbarElements == "full"){
			toolbarElements = elm.getAllElements();
			if(webix.isUndefined(view.config.bottombar))
				view.config.bottombar = true;
		}
		elements =   ui.toolbarElements(view, toolbarElements);
	}
	else
		elements =   ui.getButtons(view, view.config.buttons || buttons);

	var bar = {
		view:"toolbar",
		css: "webix_ssheet_toolbar webix_layout_toolbar",
		id:"bar",
		padding:0,
		elements: elements,
		on: {
			onChange: function (){
				var source = this.$eventSource;
				var value = source.getValue();
				var name = source.config.name;
				if(!(name == "format" && value == "custom"))
					view.callEvent("onStyleSet", [name, value]);
			},
			onItemClick: function (id){
				var viewId = view.innerId(id);
				if (actions[viewId])
					actions[viewId].call(this, view);
				else
					view.callEvent("onCommand", [{ id: viewId }]);
			}
		}
	};

	view.callEvent("onViewInit", ["toolbar", bar]);
	return bar;

}

function ready(view){
	view.attachEvent("onAfterSelect", (selected) => setValues(view, selected));
}

function setValues(view, selected) {
	var styles = {};
	var cell = selected[0];
	if(cell){
		var obj = view.getStyle(cell.row, cell.column);
		if (obj)
			styles = obj.props;
	}

	var barStyles = webix.copy(styles);

	for(var i in defaultStyles){
		if(!barStyles[i])
			barStyles[i] = defaultStyles[i];
	}

	if(barStyles.format){
		barStyles.format = barStyles.format.indexOf("fmt") !== -1 ? "custom" : barStyles.format;
	}

	view.$$("bar").setValues(barStyles);
}