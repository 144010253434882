import {encode} from "helpers/column_names.js";

export function init(view){
	var table = view._table;

	var bs = 0;
	table.attachEvent("onAreaDrag", () => bs = new Date());
	table.attachEvent("onBeforeAreaRemove", function(){
		//[FIXME] currently we don't have enough info to know
		//is user selects new area, and removing need to be blocked
		//or is it some API which attempts to remove the area
		if (view.$handleSelection && (new Date() - bs)<500) return false;
	});

	table.attachEvent("onBeforeAreaAdd", (area) => {
		//[FIXME] 
		//block selection of row-id column
		//temporary fix, we need to fire onBeforeBlockSelect when selection moves
		//so the common onBeforeBlockSelect handler will block rowId selection
		if (area.start.column == "rowId") return false;

		//if we have an active editor, we need to close it on block selection
		//ignore, when selecting the editor's cell
		var editor = table.getEditor();
		if (editor && (
			editor.row != area.start.row || editor.column != area.start.column ||
			editor.row != area.start.row || editor.column != area.start.column ))
			table.editStop();

		if (!view.$handleSelection) return true;
		

		let blockStart = encode[area.start.column] + area.start.row;
		let blockEnd = encode[area.end.column] + area.end.row;

		var res = view.$handleSelection(area.start, area.end, blockStart, blockEnd);

		if (res !== false){
			view.$handleSelection = null;
			table.removeSelectArea();
		}

		return res;
	});

	webix.event(table.$view, "mousedown", function(e){
		if (view.$handleSelection)
			return webix.html.preventEvent(e);
	});
}
