import * as add from "api/add_row_col.js";
import * as lcl from "api/lock_cell.js";
import * as fcl from "api/freeze.js";
import * as hbr from "api/hide_gridlines.js";
import * as hhd from "api/hide_headers.js";
import * as hrc from "api/hide_rows.js";
import * as mat from "api/math.js";
import * as ops from "api/operations.js";
import * as cor from "api/core.js";
import * as sav from "api/save.js";
import * as dpd from "api/dropdown.js";
import * as cfm from "api/confirm.js";
import * as und from "api/undo.js";

export function api(view){
	var all = [add, lcl, fcl, hbr, hhd, hrc, mat, ops, cor, sav, dpd, cfm, und];
	for (var i = 0; i < all.length; i++)
		webix.extend(view, all[i]);
}