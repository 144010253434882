import {group} from "operations/undo.js";
import {selectAll} from "operations/column_operations.js";

export function init(view){
	var grid = view.$$("cells");

	webix.UIManager.addHotKey("any", (view, ev) => {
		//ignore shift key
		if ((ev.which || ev.keyCode)  == 16) return;

		var sel = view.getSelectedId(true);
		if (sel.length && grid.config.editable){
			grid.$anyKey = true;
			grid.edit(sel[0]);
		}
	}, grid);

	webix.UIManager.addHotKey("enter", (view) => {
		var sel = view.getSelectedId(true);
		if (sel.length && grid.config.editable){
			if(view.config.liveEditor)
				grid.callEvent("onBeforeLiveEditor", [sel[0]]);
			else
				grid.edit(sel[0]);
		}
	}, grid);

	webix.UIManager.addHotKey("backspace", () => del(view), grid);
	webix.UIManager.addHotKey("delete", () => del(view), grid);

	var key = webix.env.isMac ? "command" : "ctrl";
	var redo = webix.env.isMac ? "command-shift-z" : "ctrl-y";

	webix.UIManager.addHotKey(redo, () => view.redo(), grid);
	webix.UIManager.addHotKey(key+"-a", () => { selectAll(view); }, grid);
	webix.UIManager.addHotKey(key+"-z", () => view.undo(), grid);
	webix.UIManager.addHotKey(key+"-b", () => styleHotKey(view, "font-weight"), grid);
	webix.UIManager.addHotKey(key+"-i", () => styleHotKey(view, "font-style"), grid);
	webix.UIManager.addHotKey(key+"-u", (v, e) => ctrlU(view, e), grid);
	webix.UIManager.addHotKey(key+"-p", (v, e) => ctrlP(view, e), grid);
}

function del(view) {
	group.set(function(){
		view.eachSelectedCell((cell) => view.setCellValue(cell.row,cell.column, ""));
		view.refresh();
	});
}

function ctrlU(view, e) {
	styleHotKey(view, "text-decoration");
	return webix.html.preventEvent(e);
}
function ctrlP(view, e) {
	view.callEvent("onCommand",[{id: "print"}]);
	return webix.html.preventEvent(e);
}

function styleHotKey(view, attr) { 
	var cell = view.getSelectedId();
	if(cell){
		var style = view.getStyle(cell.row, cell.column);

		var element = view.$$("bar").elements[attr].data;
		var elementOn = element.onValue;
		var elementOff = element.offValue;

		var state = (style && style.props[attr] === elementOn)? elementOff : elementOn;
		view.callEvent("onStyleSet", [attr, state]);
	}
}