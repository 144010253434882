import {group} from "./undo.js";
import {eachRange} from "helpers/column_names.js";

//do not change order, it will break server side compatibility
//add new options to the end of this list
export const style_names = [
	"color","background","text-align",
	"font-family","font-size",
	"font-style","text-decoration","font-weight",
	"vertical-align", "wrap", "borders", "format",
	"border-right", "border-bottom","border-left", "border-top"
];

const _style_map = {
	"vertical-align": {
		"top": function(){
			return "display: block;";
		},
		"middle": function(obj){
			var align = "flex-start";
			if(obj["text-align"] == "center")
				align = "center";
			else if(obj["text-align"] == "right")
				align = "flex-end";
			var result = "display: flex; align-items: center;justify-content:"+align+";";
			return result;
		},
		"bottom": function(obj){
			var align = "flex-start";
			if(obj["text-align"] == "center")
				align = "center";
			else if(obj["text-align"] == "right")
				align = "flex-end";
			var result = "display: flex; align-items:flex-end;justify-content:"+align+";";
			return result;
		}
	},
	"wrap": {
		"wrap": function(){
			return "white-space: normal !important; line-height:normal !important; padding-top:7px;padding-bottom:7px;";
		},
		"nowrap": function(){
			return "";
		}
	},
	"format":"",
	"borders": "",
	"border-left":function(obj){
		if(obj["border-left"])
			return "border-left: 1px solid "+obj["border-left"]+ " !important;";
		return "";
	},
	"border-top":function(obj){
		if(obj["border-top"])
			return "border-top: 1px solid "+obj["border-top"]+ " !important;";
		return "";
	},
	"border-right":function(obj){
		if(obj["border-right"])
			return "border-right: 1px solid "+obj["border-right"]+ " !important;";
		return "";
	},
	"border-bottom": function(obj){
		if(obj["border-bottom"])
			return "border-bottom: 1px solid "+obj["border-bottom"]+ " !important;";
		return "";
	}
};


const border_checks = {
	"border-left":		function(cell, area, mode){ return cell.column == area.start.column || mode =="no"; },
	"border-right":		function(cell, area, mode){ return cell.column == area.end.column || mode == "all" || mode =="no"; },
	"border-top":		function(cell, area , mode){ return cell.row == area.start.row || mode =="no"; },
	"border-bottom":	function(cell, area, mode){ return cell.row == area.end.row || mode == "all"|| mode =="no"; }
};

const _style_handlers = {
	"borders": function(view, style, value, cell){
		var area = view.$$("cells").getSelectArea();
		value = value.split(",");
		var type = value[0];
		var color = value[1];

		var modes = ["border-left", "border-right", "border-bottom", "border-top"];

		if(type == "no")
			color = "";
		else if(type == "top-bottom"){
			modes = ["border-top","border-bottom"];
		}
		else if (type != "all" && type != "outer")
			modes = ["border-"+type];
		
		for (var i = 0; i < modes.length; i++){
			var mode = modes[i];
			let result = view.callEvent("onAction", ["check-borders", {row:cell.row, column:cell.column, area, type, mode}]);
			if (result === true || border_checks[mode](cell, area, type))
				style = _updateStyle(view, style, mode, color, cell);
		}
		return style;
	}
};

var sizeEl;
export function init(view){
	view.attachEvent("onStyleSet", (name, value) => _applyStyles(view, name, value));
	view.attachEvent("onDataParse", (data) => _parse(view, data));
	view.attachEvent("onDataSerialize", (data) => _serialize(view, data));
	view.attachEvent("onReset", () => reset(view));
	view.attachEvent("onUndo", (type, row, column, style) => {
		if(type == "style")
			_undoStyle(view, row, column, style);
	});

	reset(view);

	if (!sizeEl)
		sizeEl = webix.html.create("DIV", { style:"visibility:hidden; position:absolute; top:0px; left:0px; overflow:hidden;"}, "");
}

function reset(view){
	view._styles = {};
	view._styles_cache = {};
	view._styles_max = 1;

	let prefix = ".wss_"+view.$index;
	webix.html.removeStyle(prefix);
}

export function getStyle(view, cell){
	var styles = view.getRow(cell.row).$cellCss;
	if (styles){
		var styleid = styles[cell.column];
		if (styleid)
			return view._styles[styleid];
	}
	return null;
}

// undo
function _undoStyle(view, row, column, style){
	var cell = {row: row, column: column};
	setStyle(view, cell, style);
}

function _serialize(view, obj){
	var styles = [];

	for (var key in view._styles_cache)
		styles.push([view._styles_cache[key].id, key]);

	obj.styles = styles;
}

export function addStyle(view, props, origin){
	var style = {
		props:_styleFromText(";;;;;;;;;;;;;;;")
	};

	if (origin)
		for (var key in origin.props)
			style.props[key] = origin.props[key];

	for (let  key in props)
		style.props[key] = props[key];
		

	style.text = _styleToText(style);
	var cache = view._styles_cache[style.text];
	if (cache)
		return cache;

	_addStyle(view, style);

	return style;
}

function _parse(view, obj){
	if (obj.styles)
		for (let i=0; i < obj.styles.length; i++){
			var styleObj = obj.styles[i];
			var style = {
				id: styleObj[0],
				text:styleObj[1],
				props: _styleFromText(styleObj[1])
			};

			_addStyle(view, style, true);
		}

	for (let i = 0; i < obj.data.length; i++){
		var [row, column, , css] = obj.data[i];

		if (css)
			update_style_data(view.getRow(row), column, view._styles[css]);
	}
}

function _applyStyles(view, name, value){
	//this - spreadsheet
	group.set(function(){
		view.eachSelectedCell(function(cell){
			_applyCellStyles(view, cell, name, value);
		});
	});
	view.refresh();
}

function _applyCellStyles(view, cell, name, value){
	var ostyle = getStyle(view, cell);
	var nstyle = _updateStyle(view, ostyle, name, value, cell);

	if (nstyle && nstyle != ostyle)
		_setStyle(view, cell, nstyle, ostyle);
}

function _updateStyle(view, style, name, value, cell){

	if(_style_handlers[name]){
		return _style_handlers[name](view, style, value, cell);
	}

	if (style && style.props[name] == value) return style;

	var nstyle = { text:"", id:0, props: (style?webix.copy(style.props):{}) };
	nstyle.props[name] = value;
	nstyle.text = _styleToText(nstyle);

	var cache = view._styles_cache[nstyle.text];
	if (cache)
		return cache;

	_addStyle(view,nstyle);

	return nstyle;
}

function update_style_data(item, column, style){
	item.$cellCss = item.$cellCss || {};
	item.$cellFormat = item.$cellFormat || {};

	if(style){
		item.$cellCss[column] = style.id;
		item.$cellFormat[column] = style.props.format || null;
	} else {
		delete item.$cellCss[column];
		delete item.$cellFormat[column];
	}
}

export function setStyle(view, cell, style){
	var old = getStyle(view, cell);
	return _setStyle(view, cell, style, old);
}

export function setRangeStyle(view, range, style) {
	eachRange(range, view, _setStyle, style);
}

export function getTextSize(view, text, css, x, y){
	let width = 0;
	let height = 0;

	sizeEl.innerHTML = text;
	sizeEl.style.width = (x||1)+"px";
	sizeEl.style.height = (y||1)+"px";
	sizeEl.className = "webix_table_cell webix_cell "+css;
	view._table.$view.appendChild(sizeEl);

	width = Math.max(width, sizeEl.scrollWidth);
	height = Math.max(height, sizeEl.scrollHeight);
	
	view._table.$view.removeChild(sizeEl);
	sizeEl.innerHTML = "";

	return { width: width, height: height };
}

function _setStyle(view, cell, style, old){
	if(view.callEvent("onBeforeStyleChange", [cell.row, cell.column, style, old])){
		update_style_data(view.getRow(cell.row), cell.column, style);
		view.saveCell(cell.row,cell.column);
		view.callEvent("onStyleChange", [cell.row, cell.column, style, old]);
	}
}

function _buildCssString(style) {
	let css = "";

	for (var key in style){
		if (style[key]){
			if( _style_map[key]){
				if(_style_map[key][style[key]])
					css += _style_map[key][style[key]](style);
				else if(typeof  _style_map[key] == "function")
					css += _style_map[key](style);
			}
			else
				css+= key+":"+style[key]+";";
		}
	}

	return css;
}

function _addStyle(view, style, silent){
	view._styles_cache[style.text] = style;

	while (!style.id || view._styles[style.id])
		style.id = "wss"+(view._styles_max++);
	view._styles[style.id] = style;

	const css = _buildCssString(style.props);
	let prefix = ".wss_"+view.$index;
	webix.html.addStyle(prefix+" ."+style.id+"{"+css+"}", prefix);

	if (!silent)
		view._save( "styles", { name:style.id, text: style.text } );
}

function _styleToText(style){
	var id = [];
	for (var i=0; i<style_names.length; i++)
		id.push(style.props[style_names[i]]);
	return id.join(";");
}

function _styleFromText(text){
	var parts = text.split(";");
	var props = {};
	for (var i=0; i<style_names.length; i++)
		props[style_names[i]] = parts[i];
	return props;
}



export function clearRangeStyle(view, range){
	group.set(function(){
		eachRange(range, view, function(view, cell){
			let style = getStyle(view,cell);
			if (style)
				setStyle(view, cell, null);
		});
	});
}

export function compactStyles(view){
	let params  = view.serialize();

	let data = params.data;
	let styles = params.styles;

	let used = {};
	for (let i=0; i<data.length; i++){
		let name = data[i][3];
		if (name)
			used[name] = 1;
	}

	let newStyles = [];
	for(let i = 0; i<styles.length; i++){
		let name = styles[i][0];
		if (used[name])
			newStyles.push(styles[i]);
	}

	params.styles = newStyles;
	reset(view);
	_parse(view, params);
}