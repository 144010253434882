import {range} from "helpers/column_names.js";
import {getAccessor} from "./sheet.js";

export function nullFilter(){ 
	var locale = webix.i18n.spreadsheet.labels;
	return "<span class='webix_ssheet_empty'>"+locale["dropdown-empty"]+"</span>";
}

export function rangeValue(view, text, extra){
	var vals;
	if (typeof text == "string"){
		var pos = range(text, view);
		var page = getAccessor(view, pos[4]);
		vals = page.getRange.apply(page, pos);
	} else {
		vals = webix.copy(text);
	}

	if (extra){
		if (extra.unique){
			let t = {};
			for (var i=vals.length-1; i>=0; i--){
				if (t[vals[i]])
					vals.splice(i,1);
				else
					t[vals[i]] = true;
			}
		}
		if (extra.order) vals.sort();

		var empty = vals.indexOf("");
		if (empty > -1){
			vals.splice(empty,1);
			if (extra.filter)
				vals.unshift(nullFilter());
		}

		if (extra.empty) vals.unshift({id: "$empty", $empty: true, value: ""});
	}

	return vals;
}

export class Ranges{
	constructor(view){
		this._master = view;
		this._ranges = {};
	}

	clear(){
		this._ranges = {};
	}

	add(name, text){
		let needUpdate = !!this._ranges[name];

		this._ranges[name] = text;

		if (needUpdate)
			this._master.callEvent("onMathRefresh", []);
	}
	getCode(name, sheet){
		if (sheet)
			return getAccessor(this._master, sheet).getRangeCode(name);

		return this._ranges[name];
	}
	remove(name){
		delete this._ranges[name];
	}
	getRanges(){
		var data = [];
		for(var name in this._ranges)
			data.push({ name, range:this._ranges[name] });

		return data;
	}

	parse(data){
		if (!data) return;

		let i = data.length;
		while(i--){
			var c = data[i];
			this._ranges[c[0]] = c[1];
		}
	}

	serialize(){
		var name, data = [];
		for( name in this._ranges)
			data.push([name,this._ranges[name]]);
		return data;
	}
}