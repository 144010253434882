webix.protoUI({
	name: "sheets",
	defaults:{
		layout: "x",
		borderless: true,
		css:"ssheet_list_sheets",
		scrollX: false,
		select: true,
		scroll:false
	},
	getVisibleCount:function(){
		return Math.floor(this.$width / this.type.width);
	}
},webix.EditAbility,webix.ui.list);