import { style_names } from "operations/styles.js";
import { defaultStyles} from "toolbar/helpers.js";

export function init(view){
	view.$exportView = function(options){
		webix.extend(options, { header: false, rawValues: true, spans: true, styles:true, math:true, xCorrection:1, ignore:{rowId:true}});
		if(options.export_mode =="excel")
			return _exportExcel(view, options);
		else
			return view._table;
	};
}

function _exportExcel(view, options){
	if(options.sheets === true) 
		options.sheets  = view._sheets.map(function(s){ return s.name;});
	else if(!options.sheets || !options.sheets.length)
		options.sheets = [view._activeSheet];
	else if(typeof options.sheets == "string")
		options.sheets = [options.sheets];

	options.dataOnly = true;

	let data = [];
	let active = view._activeSheet;

	for(let i = 0; i<options.sheets.length; i++){
		view.showSheet(options.sheets[i]);
		data = data.concat(webix.toExcel(view._table, options));
		data[i].ranges = [];

		let ranges = view.ranges.getRanges();
		for(let r = 0; r<ranges.length; r++){
			data[i].ranges.push({
				Sheet:i,
				Name:ranges[r].name,
				Ref:options.sheets[i]+"!"+ranges[r].range.replace(/(\w)/gi, function(match){ return "$"+match; })
			});
		}
		if(options.styles)
			data[i].styles = _getStyles(view, options);
	}
	view.showSheet(active);
	
	delete options.dataOnly;
	return data;
}

function _safeColor(str){
	str = str.substring(1);
	if(str.length === 3) str = str+str;
	return str;
}

function _getDefaults(){
	let d = defaultStyles;
	return {
		font:{
			sz:d["font-size"].replace("px", "")*0.75,
			name:d["font-family"].replace(/,.*$/, ""),
			//we do not export default color, but it is the right place to do it
		},
		alignment:{
			horizontal:d["text-align"],
			vertical:d["vertical-align"]=="middle"?"center":d["vertical-align"],
			wrapText:d["white-space"]!="nowrap"
		}
	};
}

function _getStyles(view, options){
	
	view.compactStyles();

	let all = view.serialize(),
		styles = all.styles,
		data = all.data,
		result = [],
		delta = 0;

	const defaults = _getDefaults();

	if(options.docHeader) result  = result.concat([{0:_getDocStyle(options.docHeader.css, defaults)}, {}]);
	if(options.header) result.push({});
	delta = result.length;

	if(styles.length){
		for(let i = 0; i<styles.length; i++){
			styles[styles[i][0]] = _getCellStyle(styles[i][1]);
		}
		for(let i = 0; i<data.length; i++){
			let rind = data[i][0]-1+delta,
				cind = data[i][1]-1;
			result[rind] = result[rind] || {};

			if(data[i][3] && styles[data[i][3]])
				result[rind][cind] = styles[data[i][3]];
			else //default cell style
				result[rind][cind] = defaults;
		}
	}
	if(options.docFooter) result  = result.concat([{},{0:_getDocStyle(options.docFooter.css, defaults)}]);

	return result;
}

function _getCellStyle(styles){
	let str = styles.split(";"), stl = { font:{}, alignment:{}, border:{}};

	for(let s = 0; s<str.length; s++){
		if(str[s]){
			switch (style_names[s]){
				case "color": 
					stl.font.color = {rgb:_safeColor(str[s])};
					break;
				case "background":{
					let fill = _safeColor(str[s]);
					if(fill && fill.toLowerCase() !=="ffffff")
						stl.fill = { fgColor:{ rgb:fill}};
					break;
				}	
				case "text-align":
					stl.alignment.horizontal = str[s];
					break;
				case "font-family":
					stl.font.name = str[s].replace(/,.*$/, ""); // cut off fallback font
					break;
				case "font-size":
					stl.font.sz = str[s].replace("px", "")*0.75; //px to pt conversion
					break;
				case "font-style":
					stl.font.italic = true;
					break;
				case "text-decoration":
					stl.font.underline = true;
					break;
				case "font-weight":
					stl.font.bold = true;
					break;
				case "vertical-align":
					stl.alignment.vertical = str[s] == "middle"?"center":str[s];
					break;
				case "wrap":
					stl.alignment.wrapText = str[s] == "wrap";
					break;
				case "borders":
					break;
				case "format":
					break;
				case "border-right":
					stl.border.right = { color:{ rgb:_safeColor(str[s])}, style:"thin"};
					break;
				case "border-bottom":
					stl.border.bottom = { color:{ rgb:_safeColor(str[s])}, style:"thin"};
					break;
				case "border-left":
					stl.border.left = { color:{ rgb:_safeColor(str[s])}, style:"thin"};
					break;
				case "border-top":
					stl.border.top = { color:{ rgb:_safeColor(str[s])}, style:"thin"};
					break;
			}
		}
	}
	return stl;
}

function _getDocStyle(css){
	if(!css) return {};
	var str = [];
	for(let i =0; i<style_names.length; i++)
		str.push(css[style_names[i]]||"");
	return _getCellStyle(str.join(";"));
}

