export const actions = {
	span: function(view){
		var range = view.$$("cells").getSelectArea();
		if(range){
			if(isMerged(view,range)){
				view.splitCell();
			}
			else{
				let values = [];
				for(let r = range.start.row; r<=range.end.row; r++){
					for(let c = range.start.column; c<=range.end.column; c++){
						let value = view.getCellValue(r,c);
						if(value) values.push(value);
						if(values.length >1) break;
					}
					if(values.length >1) break;
				}
				if(values.length>1){
					view.confirm({
						text:webix.i18n.spreadsheet.labels["merge-cell-confirm"],
						callback:function(res){
							if(res) view.combineCells();
						}
					});
				}
				else
					view.combineCells();
			}
		}
	},
	undo: function(view){
		view.undo();
	},
	redo: function(view){
		view.redo();
	},
	"hide-gridlines": function(view){
		view.hideGridlines("toggle");
	},
	"hide-headers": function(view){
		view.hideHeaders("toggle");
	},
	"freeze-columns": function(view){
		var table = view.$$("cells");
		var select = table.getSelectedId();
		view.freezeColumns(select && select.column!="rowId"?select.column : 0);
	},
	"freeze-rows":function(view){
		var table = view.$$("cells");
		var select = table.getSelectedId();
		view.freezeRows(select ? select.row : 0);
	}
};

function isMerged(view,range){
	var i, j,
		c0 = range.start,
		c1 = range.end;

	for( i = c0.row*1; i <= c1.row*1; i++ ){
		for( j = c0.column*1; j <= c1.column*1; j++ ){
			if(view.$$("cells").getSpan(i, j))
				return true;
		}
	}
	return false;
}
