export function hideHeaders(view, state = true){
	var grid = view.$$("cells");
	if (state === "toggle")
		state = grid.config.header;

	if(grid.config.header && state){
		grid.config.header = false;
		if (grid.isColumnVisible("rowId"))
			grid.hideColumn("rowId", {}, true, true);
		grid.refreshColumns();
	}
	else if(!grid.config.header && !state){
		grid.config.header = true;
		if (!grid.isColumnVisible("rowId"))
			grid.showColumn("rowId", {}, true);
		grid.refreshColumns();
	}
}

export function serialize(view, data){
	data.table.headers = view.$$("cells").config.header?1:0;
}

export function load(view, data){
	var mode = false;
	if(data.table && !webix.isUndefined(data.table.headers))
		mode = !data.table.headers;
	hideHeaders(view, mode);
}