import * as m1  from "./hide_gridlines.js";
import * as m2  from "./hide_headers.js";
import * as m3  from "./lock_cell.js";
import * as m4  from "./dropdown.js";
import * as m5  from "./filter.js";
import * as m6  from "./formats.js";
import * as m7  from "./hide_row_col.js";

export function init(view){
	var modules = [m1,m2,m3,m4,m5,m6, m7];
	view.attachEvent("onDataSerialize", function(data){
		for (var i = 0; i < modules.length; i++){
			if(modules[i].serialize)
				modules[i].serialize(view,data);
		}

	});
	view.attachEvent("onDataParse", function(data){
		for (var i = 0; i < modules.length; i++){
			if(modules[i].load)
				modules[i].load(view,data);
		}
	});
}