import "views/align.js";
import "views/border.js";
import "views/color.js";
import "views/dialog.js";
import "views/form.js";
import "views/formlate.js";
import "views/icons.js";
import "views/list_suggest.js";
import "views/live_editor.js";
import "views/separator.js";
import "views/sheets.js";
import "views/toggle.js";
import "views/toolbar_title.js";
import "views/button.js";
import "views/multicheckbox.js";
import "views/formlist.js";
