import {getTextSize} from "./styles.js";

export function selectRow(row, view, endRow){
	var start = {row:row, column:1 };
	var last = {row:(endRow || row), column:view.config.columnCount };
	_selectRange(start, last, view);
}

export function selectAll(view){
	var order = view.$$("cells").data.order;
	var start = {row: order[0], column: 1};
	var last = {row: order.length, column: view.config.columnCount};

	_selectRange(start, last, view);
}

export function selectColumn(column, view, endColumn){
	var startRow = view.$$("cells").data.order[0];
	var lastRow = view.$$("cells").data.order.length;

	var start = {row: startRow, column: column};
	var last = {row: lastRow, column: (endColumn || column) };

	_selectRange(start, last, view);
}

export function isColSelected(column, view){
	const selectedArea = view.$$("cells").getSelectArea();
	if (selectedArea){
		if (column >= selectedArea.start.column && column <= selectedArea.end.column)
			return true;
	}
	return false;
}

export function isCellSelected(row, column, view){
	return isRowSelected(row, view) && isColSelected(column, view);
}

export function isRowSelected(row, view){
	const selectedArea = view.$$("cells").getSelectArea();
	if (selectedArea){
		if (row >= selectedArea.start.row && row <= selectedArea.end.row)
			return true;
	}
	return false;	
}

export function selectColumns(column, view) {
	const selectedArea = view.$$("cells").getSelectArea();
	
	if (!selectedArea)
		selectColumn(column, view);
	else {
		let col1 = Math.min(selectedArea.start.column, selectedArea.end.column, column);
		let col2 = Math.max(selectedArea.start.column, selectedArea.end.column, column);
		selectColumn(col1, view, col2);
	}
}

function _selectRange(a, b, view){
	view.$$("cells").addSelectArea(a, b);
}


export function adjustColumn(column, view) {
	let width = 25;

	view._table.eachRow(function(row) {
		const rowobj = this.getItem(row);
		const text = this.getText(row, column);
		let style = view.getStyle(row, column);
		let css = style ? style.id : "";

		if (text) {
			let size = getTextSize(view, text, css, 0, rowobj.$height);
			if (size.width > width) width = size.width;
		}
	});
	view._table.setColumnWidth(column, width);
}

export function adjustRow(row, view) {
	let height = 25;
	
	view._table.eachColumn(function(column) {
		const text = this.getText(row, column);
		let style = view.getStyle(row, column);
		let css = style ? style.id : "";

		if (text) {
			let size = getTextSize(view, text, css, this.getColumnConfig(column).width, 0);
			if (size.height > height) height = size.height;
		}
	});
	view._table.setRowHeight(row, height);
}

export function highlightColRow(start,end, view){
	if(!view._table.config.header) return;
	
	var sr = start.row, er = end.row, sc = start.column, ec = end.column;
	if(er<sr) [sr, er] = [er, sr];
	if(ec<sc) [sc, ec] = [ec, sc];

	view._table.eachRow(function(row){
		if(row >=sr && row<=er)
			view._table.addCellCss(row, "rowId", "webix_highlight");
		else 
			view._table.removeCellCss(row, "rowId", "webix_highlight");
	});

	view._table.eachColumn(function(col){
		var node = view._table.getHeaderNode(col);
		if(!node) return;

		if(col >=sc && col<=ec)
			webix.html.addCss(node, "webix_highlight");
		else
			webix.html.removeCss(node, "webix_highlight");
	});
}






