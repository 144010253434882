export function _save(name, data) {
	var save = this.config.save;
	var url = "";
	if (typeof save === "string")
		url = save + "/" + name;
	else if(save && save[name]) 
		url = save[name];

	if (url){
		if (url.$proxy && url.save)
			url.save(this, data, null, null);
		else
			webix.ajax().post(url, data);
	}
}

export function saveCell(row, column){
	var style = this.getStyle(row, column);

	this._save("data", {
		row: row,
		column: column,
		value: this.getCellValue(row, column),
		style: (style ? style.id : "")
	});
}