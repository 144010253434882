import {ui} from "toolbar/helpers.js";
import {formatHelpers} from "operations/formats.js";

// options for 'font-family' select
export const fontFamily = [
	{id: "Arial", value: "Arial"},
	{id: "'PT Sans', Tahoma", value: "PT Sans"},
	{id: "Tahoma", value: "Tahoma"},
	{id: "Verdana", value: "Verdana"},
	{id: "Calibri, Tahoma", value:"Calibri"}
];

// options for 'format' select
function getCellFormats(){
	var locale = webix.i18n.spreadsheet.labels;
	return [
		{id: "-1", value: locale.common },
		{id: "price", value: locale.currency, example: "98.20"},
		{id: "int", value: locale.number, example: "2120.02"},
		{id: "percent", value: locale.percent, example:"0.5"},
		{id: "custom", value: locale["custom-format"]},
	];
}

function getColumnOperation(){
	var locale = webix.i18n.spreadsheet.menus;
	return [
		{ id:"add",  group:"column", value:locale["insert-column"]},
		{ id:"del",  group:"column", value:locale["delete-column"]},
		{ id:"show", group:"column", value:locale["show-column"]},
		{ id:"hide", group:"column", value:locale["hide-column"]}
	];
}

function getRowOperation(){
	var locale = webix.i18n.spreadsheet.menus;
	return [
		{ id:"add",   group:"row", value:locale["insert-row"]},
		{ id:"del",   group:"row", value:locale["delete-row"]},
		{ id:"show",  group:"row", value:locale["show-row"]},
		{ id:"hide",  group:"row", value:locale["hide-row"]}
	];
}


// options for 'clear' select
function getClearOperation(){
	return [
		{id: "clear-value",               value: getLocaleClear("value")},
		{id: "clear-style",               value: getLocaleClear("style")},
		{id: "clear-conditional-formats", value: getLocaleClear("conditional-formats")},
		{id: "clear-dropdown-editors",    value: getLocaleClear("dropdown-editors")},
		{id: "clear-all",                 value: getLocaleClear("all")}
	];
}

function getLocaleClear(value){
	var locale = webix.i18n.spreadsheet.menus;
	return locale["clear"] + " " + locale["clear-"+value].toLowerCase();
}

// options for 'font-size' select
function getFontSize(){
	var numbers = [
		"8","9","10","11","12","14","15",
		"16","18","20","22","24","28","36"
	];
	var  fontSize = [];

	for(var i =0; i < numbers.length; i++){
		fontSize.push({id: numbers[i]+webix.i18n.spreadsheet.labels.px, value: numbers[i]});
	}
	return fontSize;
}

export const buttonsMap = {
	"font-family": function(){
		return  ui.select({name: "font-family", tooltip: "Font family", options:fontFamily, width: 100});
	},
	"font-size": function(){
		return  ui.select({name: "font-size", tooltip: "Font size", options:getFontSize(), width: 62});
	},
	"font-weight": function(){
		return ui.button({name:"font-weight",label: "B", css: "webix_ssheet_bold",
			tooltip:"Bold",onValue:"bold",offValue: "normal"});
	},
	"font-style": function(){
		return ui.button({name: "font-style",label: "I",css:"webix_ssheet_italic",
			tooltip: "Italic",onValue: "italic",offValue: "normal"});
	},
	"text-decoration": function(){
		return ui.button({name: "text-decoration",label: "U",css: "webix_ssheet_underline",
			tooltip: "Underline",onValue: "underline",offValue: "normal"});
	},
	"color": function(){
		return ui.colorButton({name: "color",icon:"font",css:"webix_ssheet_color"});
	},
	"background": function(){
		return  ui.colorButton({name: "background",icon:"paint-brush",css:"webix_ssheet_background"});
	},
	"borders": function(){
		return ui.borders({ name: "borders" });
	},
	"text-align": function(){
		var locale = webix.i18n.spreadsheet.tooltips;
		return ui.align({name: "text-align", tooltip: "Horizontal Align", css: "webix_ssheet_align", options:[
			{id: "left", css: "webix_ssheet_icon_left", tooltip: locale["align-left"]},
			{id: "center", css: "webix_ssheet_icon_center", tooltip: locale["align-center"]},
			{id: "right", css: "webix_ssheet_icon_right", tooltip: locale["align-right"]}
		]});
	},
	"vertical-align": function(){
		var locale = webix.i18n.spreadsheet.tooltips;
		return  ui.align({name: "vertical-align", tooltip: "Vertical Align", css: "webix_ssheet_align", options:[
			{id: "top", css: "webix_ssheet_icon_top", tooltip: locale["align-top"]},
			{id: "middle", css: "webix_ssheet_icon_middle", tooltip: locale["align-middle"]},
			{id: "bottom", css: "webix_ssheet_icon_bottom", tooltip: locale["align-bottom"]}
		]});
	},
	"wrap": function(){
		return ui.iconButton({name: "wrap", onValue: "wrap",offValue:"nowrap"});
	},
	"format": function(){
		return ui.select({
			name: "format", tooltip: "Cell data format", width: 100, options:getCellFormats(), popupWidth: 180,
			popupTemplate: function(obj){
				//make it look like an actual cell
				var format =  formatHelpers[obj.id] ? formatHelpers[obj.id].getFormat : "";
				var cell = {css:""};
				var example = format && obj.example?format(obj.example, cell):"";
				return obj.value + (format?"<span class='webix_ssheet_right"+(cell.css?" "+cell.css:"")+"'>"+example+"</span>":"");
			},
			popupEvents:{
				onItemClick: function(id){
					if(id == "custom")
						this.getTopParentView().callEvent("onCommand", [{id:id}]);
				}
			}
		});
	},
	"column": function(){
		return {
			name: "column",
			view: "ssheet-button", icon: "column",
			arrow: true, area: true, width: 55,
			options:getColumnOperation()
		};
	},
	"row": function(){
		return {
			name: "row", view: "ssheet-button", icon: "row",
			arrow: true, area: true, width: 55,
			options:getRowOperation()
		};
	},
	"clear": function(){
		return {
			name: "clear", view: "ssheet-button", icon: "clear_styles",
			arrow: true, area: true, width: 55,
			options:getClearOperation()
		};
	}
};
