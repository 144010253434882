export function  freezeColumns(column){
	const grid = this.$$("cells");
	const oldColumn = grid.config.leftSplit-1;

	if (column === false)
		column =0;

	if( webix.isUndefined(column) ){
		let sel = this.getSelectedId(true);
		column = sel.length?sel[0].column:0;
	}

	if(grid.config.leftSplit>1){
		var diff = column - oldColumn;
		if(!diff)
			column = 0;
	}

	grid.unselect();
	grid.define("leftSplit", column?column+1:1);
	grid.refreshColumns();

	this.callEvent("onAction", [ "freeze-column", { value: oldColumn, newValue: column}]);
}

export function freezeRows(row){
	const grid = this.$$("cells");
	const oldRow = grid.config.topSplit;
	
	//if no row selected or at split row
	if(oldRow == row) row = 0;
		
	grid.unselect();
	grid.define("topSplit", row || 0);
	grid.refreshColumns();

	this.callEvent("onAction", [ "freeze-row", { value: oldRow, newValue: row}]);
}
