import {addStyle} from "./styles.js";

const formats = {};
let formatsCount = 0;

export const formatHelpers = {
	price : {
		getFormat : function(value, extra){
			extra.css = "webix_ssheet_format_price";
			return webix.i18n.priceFormat(value);
		},
		values:{
			zeros: webix.i18n.priceSettings.decimalSize, 
			symbol: webix.i18n.priceFormat(""),
			separator: 1, negative:1
		}
	},
	"int" : {
		getFormat : function(value, extra){
			extra.css = "webix_ssheet_format_int";
			return webix.i18n.numberFormat(value);
		},
		values:{
			zeros: webix.i18n.decimalSize, 
			separator: 1, negative:1
		}
	},
	percent : {
		getFormat : function(value, extra){
			extra.css = "webix_ssheet_format_percent";
			return Math.round(value*100)+"%";
		},
		values:{
			zeros: 0, separator: 0, negative:1
		}
	}
};

const formatSources = {};
const formatCache = {};

for(let i in formatHelpers){
	formatSources[i] = joinFormatValues(i, formatHelpers[i].values);
	formatCache[formatSources[i]] = i;
}

export function serialize(view, data){
	var formats = [];

	for(let i in formatSources){
		if(i!="percent" && i!="int" && i!="price") //exclude default formats
			formats.push([i,formatSources[i]]);
	}
	data.formats = formats;
}

export function load(view,data){
	var i,
		formats = data.formats;

	if(formats)
		for(i = 0; i < formats.length; i++)
			getFormatName(formats[i][1],formats[i][0]);
}

export function getFormat(name){
	return formatHelpers[name] ? formatHelpers[name].getFormat : formatHelpers[name];
}

export function getFormatSource(name){
	return formatSources[name];
}

function getFormatName(str, name, counter){
	if (formatCache[str]) return formatCache[str];
	let values;
	if(name){
		values = name.values;
		name = counter ? "fmt_"+name.type+(formatsCount++) : name.type;
	}
	else
		name = "fmt"+(formatsCount++);
	formats[name] = str;

	formatHelpers[name] = {getFormat:format2code(str)};
	if(values) formatHelpers[name].values = values;
	formatSources[name] = str;
	formatCache[str] = name;

	return name;
}

export function addFormat(view, row, column, format, type){
	var old = view.getStyle(row, column);
	var fmtstring = getFormatName(format, type, true);
	var nev = addStyle(view, { format: fmtstring }, old);
	view.setStyle(row, column, nev);
}


function splitFormat(str){
	var conditional = str.match(/.*\[[><=].*/g);
	var parts = str.split(";");
	if (!conditional){
		if (parts.length > 1){
			parts[0] = (parts.length > 2 ? "[>0]" : "[>=0]")+parts[0];
			parts[1] = "[<0]"+parts[1];
			if (parts[2])
				parts[2] = "[=0]"+parts[2];
		}
	}
	return parts;
}

export function format2code(str){
	let parts = splitFormat(str);
	let code = ["var spaces = \"&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\";"];
	for (var i=0; i<parts.length; i++){
		let check = "";
		let color = "";
		let line = parts[i];
		let start = line.indexOf("[");
		if (start !== -1){
			if (line[1].match(/[><=]/)){
				let end = line.indexOf("]");
				check = line.substr(start+1, end-start-1);
				line = line.substr(end+1);
			}
		}

		start = line.indexOf("[");
		if (start !== -1){
			let end = line.indexOf("]");
			color = line.substr(start+1, end-start-1);
			line = line.substr(end+1);
		}

		code.push(genCode(check, color.toLowerCase(), line, i, parts.length > 0));
	}

	return new Function("val","extra", code.join("\n")+" return val;");
}

function genCode(check, color, line, ind, isAbs){
	var str = "";
	if (check){
		if (!ind) str+="if"; else str+="else if";
		if (check[0] === "=") check = "="+check;
		str+="(val"+check+"){";
		if (isAbs) str+="val = Math.abs(val);";
	}
	if (color)
		str+="extra.css = \"webix_ssheet_format_"+color+"\";";
	if (line.indexOf("%") !== -1)
		str += "val = val*100;";
	str += genFormatCode(line); 
	return str+(check?"}":"");
}

function genFormatCode(line){
	if (!line) return "return val;";
	var isQuote = 0;
	var str = "return \"\"";
	var fmt = "";
	var comma = false;
	var inserted = false;
	
	for (var i=0; i<line.length; i++){
		if (line[i] == "\""){
			if (isQuote){
				str += `+"${line.substr(isQuote, i-isQuote)}"`;
				isQuote = false;
				continue;
			} else 
				isQuote = i;
		}
		if (isQuote) continue;
		else if (line[i] === "."){
			fmt+=".";
		} else if (line[i] === "0" || line[i] === "#" || line[i] === "?"){
			if (!inserted) { str+="+fmt"; inserted=true; }
			fmt+=line[i];
		} else if (line[i] === ",")
			comma = true;
		else
			str += `+"${line[i]}"`;
	}

	if (fmt)
		return numberFormat(fmt, comma)+";"+str + ";";
	else
		return str+";";
}


function numberFormat(fmt, comma){
	var str = "if (isNaN(val)) var fmt = val; else {";
	var [left, right] = fmt.split(".");
	right = (right || "").split("").reverse().join("");

	//calculate indexes in mask
	var lzeros = left.indexOf("0"); if (lzeros>=0) lzeros = left.length - lzeros;
	var lfills = left.indexOf("?"); if (lfills>=0) lfills = left.length - lfills;
	var rzeros = right.indexOf("0"); if (rzeros>=0) rzeros = right.length - rzeros;
	var rfills = right.indexOf("?"); if (rfills>=0) rfills = right.length - rfills;
	var rmax = right.length;

	str += `
	var decimal = '.';
	var parts = val.toFixed(${rmax}).split(decimal);
	var left = parts[0];
	var lsize = left.length; 
	var right = parts[1] || "";
	if (left.length < ${lzeros}) left = "0000000000".substr(0, ${lzeros} - left.length)+left;
	if (left.length < ${lfills}) left = spaces.substr(0, 6*(${lfills} - left.length))+left;
	if (${comma}) { 
		var buf = [];
		var start = 3;
		while (lsize > start) { buf.push(left.substr(left.length-start,3)); start+=3; }
		var last = left.substr(0,left.length-start+3);
		if (last !== "-")
			buf.push(last);
		else
			buf.push("-"+buf.pop());

		left = buf.reverse().join(",");
	}
	if (right){
		var zpoint = right.length-1;
		while (zpoint >= ${rzeros}){
			if (right[zpoint] !== "0"){
				break;
			}
			zpoint--;
		}
		
		if (zpoint <= right.length)
			right = right.substr(0, zpoint+1);

		if (right.length < ${rfills}){
			right += spaces.substr(0, 6*(${rfills} - right.length));
		}
	}
	var fmt = left+(right?decimal:"")+right;
	`;

	return str+"}\n";
}

export function joinFormatValues(type, values){
	const zeros = values.zeros ? "."+Array(values.zeros + 1).join("0") : "";
	const separator = values.separator && type != "percent" ? "," : "";

	const sign = values.negative < 3 ? "-" : "";
	const negativeColor = values.negative != 1 ? "[red]" : "";

	let symbol;
	let value = separator+"0"+zeros;
	if(type === "price" || type === "int"){
		symbol = type === "price" ? formatHelpers.price.values.symbol : "";
		value = "[<0]"+negativeColor+symbol+sign+value+";[>=0]"+symbol+value;
	}
	else if(type === "percent"){
		symbol = "%";
		value = "[<0]"+negativeColor+sign+value+symbol+";[>=0]"+value+symbol;
	}

	return value;
}