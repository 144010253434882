import {nullFilter} from "math/ranges.js";
import  * as drd from "operations/dropdown.js";
import {getFilters} from "operations/filter.js";
import {toRange} from "helpers/column_names.js";


export function getRow(id){
	return this._table.getItem(id);
}

export function setCellFilter(row, column, range){
	if(!range)
		range = {end:{row:row}};

	if (range.end){
		var endRow = range.end.row;
		range = getFilterRange(this, row, column, endRow);
	}
	this.setCellEditor(row,column, { editor:"richselect", options: range, useAsFilter:true });
	this.addFilter(row,column);
}

function getFilterRange(view,  row, column, endRow){
	if (row === endRow){
		for (endRow = row; endRow < view.config.rowCount; endRow++){
			if(!view.getCellValue(endRow+1,column))
				break;
		}
	}
	return toRange(row+1, column, endRow, column);
}

export function setCellEditor(row, column, editor) {
	let line = this._table._ssEditors[row] = this._table._ssEditors[row] || {};
	let value = line[column]|| this._table.getItem(row)[column];
	line[column] = editor;

	if (editor && editor.editor)
		this._table.addCellCss(row,column, "ss_filter");
	else{
		delete this._table._ssEditors[row][column];
		this._table.removeCellCss(row,column, "ss_filter");
	}
	this.callEvent("onAction", ["dropdown", { row, column, value, newValue:editor }]);
}

export function getCellEditor(row, column){
	let line = this._table._ssEditors[row] || {};
	return line[column] || null; 
}

export function addFilter(row,column){
	return drd.addCellFilter(this,row,column);
}

export function removeFilter(){
	var fs = getFilters(this._table);
	for	(let i=0; i<fs.length; i++){
		let [row,column] = fs[i];
		let value = this.getCellEditor(row, column);
		this.setCellEditor(row,column, null);
		this._table.removeCellCss(row,column, "ss_filter");
		this.callEvent("onAction", ["filter", {row, column, value, newValue:null }]);
	}

	this._table._ssFilters = {};
	this._table.data.filter(function(){ return true; });
}


export function filterSpreadSheet(){
	this._table.data.silent(function(){ 
		var filters = getFilters(this._table);
		for (var i=0; i<filters.length; i++){
			let [row,column] = filters[i];
			var value = this._table.getItem(row)[column];

			var all = false;
			const isNull = (value == nullFilter());
			this._table.data.filter(function(obj){
				if (!value) return true;
				if (isNull && !obj[column]) return true;
				if (obj.id*1 <= row*1) return true;
				if (!obj[column] && obj[column] !== "") all = true;
				return all || obj[column] == value;
			}, 1, i);
		}
	}, this);

	this._table.refresh();
}
