import * as m1  from "./add_row_col.js";
import * as m6  from "./clipboard.js";
import * as m12 from "./column_operations.js";
import * as m2  from "./conditional_formats.js";
import * as m16a from "../math/math.js";
import * as m17 from "./data.js";
import * as m18 from "./dropdown.js";
import * as m7  from "./formats.js";
import * as m13 from "./hide_row_col.js";
import * as m14 from "./lock_cell.js";
import * as m3  from "./resize.js";
import * as m9  from "./selection.js";
import * as m10 from "./sorting.js";
import * as m16 from "./spans.js";
import * as m19 from "./states.js";
import * as m5  from "./styles.js";
import * as m11 from "./undo.js";
import * as m20 from "./import.js";
import * as m21 from "./filter.js";
import * as m22 from "./print.js";
import * as m23 from "./export.js";
import * as m24 from "./load.js";
import * as m25 from "./clear_styles.js";

export function operations(view){
	//order in some cases does matter
	//resize must be applied after math, to size data by the result of math calculation
	var all = [m1,m2,m5,m6,m7,m9,m10,m11,m12,m13,m14,m16,m16a,m3,m17,m18,m19,m20,m21,m22,m23,m24,m25];
	for (var i = 0; i < all.length; i++)
		if (all[i].init)
			all[i].init(view);
}