import * as aiw from "./image.js";
import * as asw from "./sparkline.js";
import * as rgs from "./ranges.js";
import * as dpd from "./dropdown.js";
import * as fmt from "./format.js";
import * as cnd from "./conditional.js";
import * as exp from "./export.js";
import * as lnk from "./link.js";
import * as pnt from "./print.js";

var bases = [ aiw, asw, rgs, dpd, fmt, cnd, exp, lnk, pnt ];
var dialogs = {};

export function init(view){
	for (var i = 0; i < bases.length; i++)
		dialogs[bases[i].action] = new bases[i].DialogBox(view);

	view.attachEvent("onCommand", function(action){
		var box = dialogs[action.id];
		if (box) box.open();
	});
}