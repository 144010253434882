import {buttonsMap} from "toolbar/buttons.js";

const toolbarSizes = {
	height: 39,
	margin: 7,
	paddingY: 1,
	sectorPadding: 5,
	sectorMargin: 0
};

// options for 'borders' select
const borders = [
	{id: "no", value: "no"},
	{id: "left", value: "left"},
	{id: "top", value: "top"},
	{id: "right", value: "right"},
	{id: "bottom", value: "bottom"},
	{id: "all", value: "all"},
	{id: "outer", value: "outer"},
	{id: "top-bottom", value: "top_bottom"}
];

// default styles for toolbar elements
export const defaultStyles = {
	"color"         : "#666666",
	"background"    : "#ffffff",
	"font-family"   : "'PT Sans', Tahoma",
	"font-size" : "15px",
	"text-align": "left",
	"vertical-align": "top",
	"white-space": "nowrap",
	"format": "-1"
};



export const ui = {
	"toolbarElements": function(view,structure){
		var checkButtons = function(cells){
			for(var i =0; i < cells.length; i++){
				var name = cells[i].$button;
				if (name){
					var base = buttonsMap[name] ? buttonsMap[name](view) : ui.iconButton({name:name});
					webix.extend(cells[i], base);
				}
				
				if(cells[i].rows)
					checkButtons(cells[i].rows);
				if(cells[i].cols)
					checkButtons(cells[i].cols);
			}
		};
		checkButtons(structure);
		return structure;
	},
	"getButtons": function(view,structure){
		var config = [];
		for(var block in structure){
			config.push(ui.elementsBlock(view, block, structure[block]));
			config.push(ui.separator());
		}
		return config;
	},
	"elementsBlock": function(view, name, columns){
		var block = {
			rows:[
				{
					padding:2,
					cols:[{
						height: toolbarSizes.height,
						margin: 2,
						cols: ui.blockColumns(view, columns)
					}]
				},
				ui.title({title:name})
			]
		};
		return block;
	},
	"blockColumns": function(view, buttons){
		var cols = [];
		for(var i =0; i < buttons.length; i++){
			let name = buttons[i];
			if(typeof name == "object"){
				cols.push(name);
			}
			else {
				var base = buttonsMap[name] ? buttonsMap[name](view) : ui.iconButton({name:name});
				cols.push(base);
			}
		}
		return cols;
	},
	button: function(config){
		return {
			view: "ssheet-toggle", width: config.width||40, id: config.name, name: config.name,  label: config.label,
			css: config.css||"", onValue: config.onValue, offValue: config.offValue,
			tooltip: webix.i18n.spreadsheet.tooltips[config.name]||""
		};
	},
	colorButton: function(config){
		return   {
			view: "ssheet-color",  css: config.css,  name: config.name, width: config.width||62,
			title: "<span class='webix_ssheet_button_icon webix_ssheet_color_button_icon webix_ssheet_icon_"+config.name.replace(/-/g,"_")+"' ></span>",
			tooltip: webix.i18n.spreadsheet.tooltips[config.name]||""
		};
	},
	iconButton: function(config){
		var btn = webix.copy(config);
		webix.extend(btn, {view: "button", type:"htmlbutton", width: 40, id: config.name,
			label: "<span class='webix_ssheet_button_icon webix_ssheet_icon_"+config.name.replace(/-/g,"_")+"'></span>",
			css: "",
			tooltip: webix.i18n.spreadsheet.tooltips[config.name]||webix.i18n.spreadsheet.menus[config.name]||""
		});
		if(config.onValue){
			webix.extend(btn,{view: "ssheet-toggle", onValue: config.onValue, offValue: config.offValue},true);
		}

		return btn;
	},
	segmented: function(config){
		return  {
			view: "segmented", name: config.name, css:config.css||"", width: config.width||115,  options:config.options
		};
	},
	select: function(config){
		webix.extend(config,{
			view: "richselect",
			id: config.name,
			value: defaultStyles[config.name],
			suggest: {
				css: "webix_ssheet_suggest",
				//fitMaster: false,
				padding: 0,
				data: config.options
			}
		});

		config.tooltip = webix.i18n.spreadsheet.tooltips[config.name]||"";
		if(config.popupWidth){
			config.suggest.fitMaster = false;
			config.suggest.width = config.popupWidth;
		}
		if(config.popupTemplate)
			config.suggest.body = {
				template: config.popupTemplate
			};
		if(config.popupEvents){
			config.suggest.body = config.suggest.body || {};
			config.suggest.body.on = config.popupEvents;
		}
		return config;
	},
	separator: function(){
		return {
			view: "ssheet-separator"
		};
	},
	title: function(config){
		var title = config.title;
		if(title.indexOf("$") === 0)
			title = "";
		title = webix.i18n.spreadsheet.labels[config.title]||title;

		return {
			template: title, view: "ssheet-bar-title", height: 24
		};
	},
	borders: function(config){
		return {view: "ssheet-borders", width:config.width||62, data: borders, id: config.name, name: config.name,
			tooltip: webix.i18n.spreadsheet.tooltips[config.name] };
	},
	align: function(config){
		return {view: "ssheet-align", value: defaultStyles[config.name], width:config.width||62, data: config.options,
			name: config.name, tooltip: webix.i18n.spreadsheet.tooltips[config.name] };
	},
	condFormat: function(config){
		return {
			view: "ssheet-cond-format",
			width: 40,
			id: config.name,
			name: config.name
		};
	}
};
