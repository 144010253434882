import {toRange,rangeObj} from "helpers/column_names.js";
import * as tbl from "table.js";
import {group} from "operations/undo.js";
import {newSheet} from "sheets.js";

export function refresh(all){
	if (all)
		this._table.refreshColumns();
	else
		this._table.refresh();
}
export function eachSelectedCell(cb){
	var cells = this.getSelectedId(true);
	for (var i=0; i<cells.length; i++)
		cb.call(this, cells[i]);
}
export function getSelectedRange(sheet){
	var area = this._table.getSelectArea();
	if (area){
		if (sheet)
			sheet = this.getActiveSheet();
		return toRange(area.start.row,area.start.column,area.end.row,area.end.column,sheet);
	}
	return "";
}
export function getSelectedId(all){
	var area = this._table.getSelectArea();
	if(!all)
		return area && area.start.row ? area.start : null;

	var selection = [];
	if(area){
		var c0 = area.start;
		var c1 = area.end;

		for(var i = c0.row; i<= c1.row; i++){
			for(var j = c0.column; j <= c1.column; j++){
				selection.push({row: i,column:j});
			}
		}
	}

	return selection;
	//return this._table.getSelectedId(all)
}
export function getCellValue(row, column){
	var item = this.getRow(row);
	var value = item["$"+column] || item[column];
	
	if (typeof value === "undefined") return "";
	return value;
}
export function setCellValue(row, column, value){
	var item = this.getRow(row);
	var old = item["$"+column]||item[column];
	
	if (this.callEvent("onBeforeValueChange",[row, column, value, old])){
		item[column] = value;
		delete item["$"+column];

		this.callEvent("onCellChange", [row, column, value, true]);
		this.saveCell(row, column);
	}
}

export function setRangeValue(range, value){
	group.set(function(){
		var pos = rangeObj(range, this);
		for (let row = pos.start.row; row <= pos.end.row; row++) {
			for (let column = pos.start.column; column <=  pos.end.column; column++) {
				this.setCellValue(row, column, value);
			}
		}
	},this);

}

export function getRow(id){
	return this._table.getItem(id);
}
export function getColumn(id){
	return this._table.getColumnConfig(id);
}
export function reset(mode){
	var obj = { data:[] };
	if (mode && mode.sheets){
		obj = newSheet(obj);
	}
	
	this.parse(obj);
}

export function _resetTable(){
	tbl.reset(this);
}
