export function hideGridlines(view, state = true){
	var grid = view.$$("cells");
	var borderless = grid.$view.className.indexOf("webix_borderless") !=-1;
	if (state === "toggle")
		state = !borderless;

	if(!borderless && state){
		webix.html.addCss( grid.$view, "webix_borderless",true);
	}
	else if(borderless && !state){
		webix.html.removeCss( grid.$view, "webix_borderless");
	}
}

export function serialize(view, data){
	data.table.gridlines = view.$$("cells").$view.className.indexOf("webix_borderless") !=-1?0:1;
}

export function load(view, data){
	var mode = false;
	if(!webix.isUndefined(data.table) && !webix.isUndefined(data.table.gridlines))
		mode = !data.table.gridlines;
	hideGridlines(view, mode);
}