import {Dialog} from "./common.js";

export const action = "excel-export";

export class DialogBox extends Dialog{
	$show(box, form){
		this.view.disable();

		form.elements.sheets.setValue(this.getSheets());
		form.elements.filename.setValue("Data");
		form.elements.filename.getInputNode().select();
	}
	$hide(){
		this.view.enable();
	}
	$init(){
		return {
			view: "ssheet-dialog",
			head: webix.i18n.spreadsheet.labels["export-title"],
			move: true,
			position: "center",
			body:{
				view:"form",
				elements:[
					{ view:"text", name:"filename",  placeholder:webix.i18n.spreadsheet.labels["export-name"]},
					{ view:"multicheckbox", name:"sheets"}
				]
			},
			on:{
				onSaveClick: () => okClick(this),
				onHideClick: () => this.close(),
				onCancelClick: () => this.close()
			}
		};
	}
	getSheets(){
		var view = this.view;
		var sheets = view._sheets;
		var value = {};

		if(sheets && sheets.length > 1){
			for(var i = 0; i<sheets.length; i++)
				value[sheets[i].name] = (view._activeSheet === sheets[i].name?1:0);
		}
		return value;
	}
}

function okClick(dialog){
	var values = dialog.$dialog.getBody().getValues();
	webix.toExcel(dialog.view, values);
	dialog.close();
}
